
.table-query-card[data-v-6c6ff1e7]{
    margin-bottom: 0 !important;
    border: none;
}
.person-security-component[data-v-6c6ff1e7]{
    padding-top: 25px;
    background-color: #fff;
    padding-bottom: 100px;
    padding-left: 30px;
}
.person-account[data-v-6c6ff1e7]{
     border-bottom: 1px solid #dcdcdc;
     margin-bottom: 10px;
}
.person-account .content[data-v-6c6ff1e7]{
     display: inline-block;
     width: 200px;
}
.person-account .text[data-v-6c6ff1e7]{
     color: #666;
}
