/* Mixins */
.person-index-component .personcenter-menu {
  text-align: center;
}
.person-index-component .personcenter-menu .personcenter-avatar {
  position: relative;
  height: 140px;
  border-bottom: 1px solid #EDEEF0;
}
.person-index-component .personcenter-menu .avatar-font {
  font-size: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #3096F0;
  line-height: 1.5;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  background: #F5F5F5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}