
.table-query-card[data-v-3a8a5056]{
    margin-bottom: 0 !important;
    border: none;
}
.person-info-component[data-v-3a8a5056]{
    padding-top: 25px;
    background-color: #fff;
}
.save-button[data-v-3a8a5056]{
    padding-left: 21%;
}
